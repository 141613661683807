<template>
  <div class="info-block_color">
    <p>
      Для размещения услуги на Вашей Интернет странице, в ее код нужно нужно добавить:
    </p>
    <ul class="list_mark">
      <li>
        форму обратного вызова c полем для ввода пользователем Вашего сайта своего номера телефона
      </li>
      <li>
        JavaScript секцию, содержащую ajax запрос на API сервер Связьтранзит, который, помимо номера
        телефона назначения, передает Ваш идентификатор и уникальный ключ группы правил
      </li>
    </ul>
    <span>Например:</span>
    <pre :class="$style.code">
                  <!--подключаем библиотеку jQuery-->
                  &#60;script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.0/jquery.min.js"&#62;&#60;/script&#62;
                  ...

                  &#60;form id="demoForm"&#62;
                     &#60;input
                      id="TargetPhoneNumber"
                      placeholder="Номер телефона с 7, например 73952718307"
                      value=""
                    /&#62;
                    &#60;input type="submit" /&#62;
                  &#60;/form&#62;
                  ...

                  &#60;script>
                    $('#demoForm').submit(function(event) {
                      //отменяем отправку формы
                      event.preventDefault();
                      //получаем телефонный номер клиента и отправляем запрос
                      var PhoneNumber = document.getElementById('TargetPhoneNumber').value;
                      $.ajax({
                        type: 'POST',
                        url: 'https://api.stranzit.ru/v1/api/Callback/Create',
                        data: {
                          CustomerId: 3114185,
                          TargetPhoneNumber: PhoneNumber,
                          GroupName: 'Имя группы правила',
                        },
                        success: function(data) {
                          //Здесь вы можете добавить действия в случае успешного вызова
                        },
                        error: function(xhr, str) {
                          //Здесь вы можете обработать ошибки
                        },
                      });
                    });
                  &#60;/script&#62;
                </pre>
    <p>, где</p>

    <span :class="$style.code">CustomerId</span>
    <span> - ваш уникальный идентификатор, равный 3114185 </span>
    <br />
    <span :class="$style.code">TargetPhoneNumber</span>
    <span>
      - значение поля для ввода текста, куда пользователи будут вписывать свой номер телефона для
      звонка
    </span>
    <br />
    <span :class="$style.code">GroupName </span>
    <span>
      - название группы правил, позволяет создавать правила с одинаковыми условиями но разными
      номерами операторов и АОН
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" module>
.code {
  color: $mainButton;
}
</style>
